import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

import PageLoadingError from '../generales/PageLoadingError';

export default function LicitacionesDetailError() {
  const intl = useIntl();

  return (
    <ContentContainer>
      <PageLoadingError />
    </ContentContainer>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  padding: 20px;
  background-color: ${(props) => props.theme.bgGray100};
`;
