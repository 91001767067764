import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { FormattedMessage, useIntl } from 'react-intl';
import { orderBy } from '../../helpers/orderBy';

import {
  selectLicitaciones,
  selectLicitacionesError,
  selectLicitacionesLoading,
} from '../../features/licitaciones/licitacionesSlice';
import { db } from '../../firebase/firebase';
import { selectUser } from '../../features/user/userSlice';
import { convertTZ } from '../../helpers/convertTZ';
import { logAnalytics } from '../../helpers/logAnalytics';
import PageContent from '../generales/PageContent';
import LicitacionesDetailMenuA from './Licitaciones_detail_menuA';
import LicitacionesDetailMenuC from './Licitaciones_detail_menuC';
import LicitacionesDetailMenuB from './Licitaciones_detail_menuB';
import LicitacionesDetailMenuE from './Licitaciones_detail_menuE';
import LicitacionesDetailSendModal from './Licitaciones_detail_sendModal';
import LicitacionesDetailMenuD from './Licitaciones_detail_menuD';
import LicitacionesDetailMenuF from './Licitaciones_detail_menuF';
import useWindowDimensions from '../../helpers/windowDimensions';
import LicitacionesDetailMenuI from './Licitaciones_detail_menuI';
import LicitacionesDetailError from './Licitaciones_detail_error';

export default function LicitacionesDetail() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { idLicitacion } = useParams();
  const licitacion = useSelector(selectLicitaciones).find((x) => x.idLicitacion === idLicitacion);
  const loadingLicitacion = useSelector(selectLicitacionesLoading);
  const errorLicitacion = useSelector(selectLicitacionesError);
  const user = useSelector(selectUser);
  const { width } = useWindowDimensions();

  const [option, setOption] = useState('menuA');
  const [data, setData] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const [errorData, setErrorData] = useState(false);
  const [forceReload, setForceReload] = useState(false);
  const [modal, setModal] = useState(false);
  const [valores, setValores] = useState([]);
  const [files, setFiles] = useState([]);
  const [filesComments, setFilesComments] = useState([]);
  const [stage, setStage] = useState(
    orderBy([...(licitacion?.stages || [])], 'value').reverse()[0]
  );

  const isStageActive = licitacion?.stageActive === (stage?.id || null);

  const stageDates = (data?.dates || []).find((x) =>
    stage ? x.idStage === stage.id : x.idStage === 'stage1'
  );

  // console.log({ licitacion, data });

  const allowFetch =
    !!licitacion?.idProject && !!licitacion?.idLicitacion && (licitacion?.stages || []).length > 0;

  useEffect(() => {
    if (allowFetch) {
      (async () => {
        try {
          const functions = getFunctions();
          const licitaciones_data_oncall = httpsCallable(functions, 'licitaciones_data_oncall');
          const result = await licitaciones_data_oncall({
            idProject: licitacion.idProject,
            idLicitacion: licitacion.idLicitacion,
          });
          setData(result.data);
          setLoadingData(false);
        } catch (err) {
          setLoadingData(false);
          setErrorData(true);
        }
      })();
    } else if (errorLicitacion) {
      setLoadingData(false);
      setErrorData(true);
    }
  }, [licitacion, errorLicitacion, forceReload, allowFetch]);

  useEffect(() => {
    if (allowFetch) {
      const q = query(
        collection(db, 'licitaciones_valores'),
        where('userId', '==', user.userId),
        where('idLicitacion', '==', licitacion.idLicitacion),
        where('idProject', '==', licitacion.idProject)
      );
      const subscriber = onSnapshot(
        q,
        (querySnapshot) => {
          setValores(
            querySnapshot.docs.map((x) => {
              return {
                idRegistro: x.id,
                ...x.data(),
              };
            })
          );
        },
        (err) => {
          console.log({ subscriber: 'licitaciones_valores', err });
        }
      );
      return () => subscriber();
    }
  }, [licitacion, allowFetch, user.userId]);

  useEffect(() => {
    if (allowFetch) {
      const q = query(
        collection(db, 'licitaciones_files'),
        where('userId', '==', user.userId),
        where('idLicitacion', '==', licitacion.idLicitacion),
        where('idProject', '==', licitacion.idProject)
      );
      const subscriber = onSnapshot(
        q,
        (querySnapshot) => {
          setFiles(
            querySnapshot.docs.map((x) => {
              return {
                idFile: x.id,
                ...x.data(),
              };
            })
          );
        },
        (err) => {
          console.log({ subscriber: 'licitaciones_files', err });
        }
      );
      return () => subscriber();
    }
  }, [licitacion, allowFetch, user.userId]);

  useEffect(() => {
    if (allowFetch) {
      const q = query(
        collection(db, 'licitaciones_files_comments'),
        where('userId', '==', user.userId),
        where('idLicitacion', '==', licitacion.idLicitacion),
        where('idProject', '==', licitacion.idProject)
      );
      const subscriber = onSnapshot(
        q,
        (querySnapshot) => {
          setFilesComments(
            querySnapshot.docs.map((x) => {
              return {
                idComment: x.id,
                ...x.data(),
              };
            })
          );
        },
        (err) => {
          console.log({ subscriber: 'licitaciones_files_comments', err });
        }
      );
      return () => subscriber();
    }
  }, [licitacion, allowFetch, user.userId]);

  // para validar que esten todos los montos agregados
  let montosValidation = true;
  (data?.categorias || []).forEach((x) => {
    const valor = valores.find((y) => y.idCategoria === x.idCategoria);
    if (x.required && !valor) {
      montosValidation = false;
    }
  });

  // para validar que esten todos los archivos agregados
  // console.log(data);
  let archivosValidation = true;
  (data?.offerDocs || []).forEach((x) => {
    const valor = files.find((y) => y.idCategoria === x.idCategoria);
    if (x.required && !valor) {
      archivosValidation = false;
    }
  });

  const periodoEntregaOfertas =
    convertTZ(new Date(), 'America/Santiago') <=
      convertTZ(new Date(licitacion?.offerEnd?._seconds * 1000), 'America/Santiago') &&
    convertTZ(new Date(), 'America/Santiago') >=
      convertTZ(new Date(licitacion?.offerStart?._seconds * 1000), 'America/Santiago');

  let noValidText = '';
  // para boton de envio de oferta
  const isValid = () => {
    if (loadingLicitacion || loadingData) {
      return false;
    } else {
      if (
        convertTZ(new Date(), 'America/Santiago') >=
        convertTZ(new Date(licitacion?.offerEnd?._seconds * 1000), 'America/Santiago')
      ) {
        noValidText =
          intl.locale === 'en'
            ? 'The deadline for sending offers has ended'
            : 'El plazo para el envío de ofertas finalizó';
        return false;
      } else if (
        convertTZ(new Date(), 'America/Santiago') <=
        convertTZ(new Date(licitacion?.offerStart?._seconds * 1000), 'America/Santiago')
      ) {
        noValidText =
          intl.locale === 'en'
            ? 'Sending offers has not yet started'
            : 'Todavía no comienza el envío de ofertas';
        return false;
      } else if (!((data?.offerDocs || []).length > 0 && archivosValidation)) {
        noValidText = intl.locale === 'en' ? 'Pending documentation' : 'Documentación pendiente';
        return false;
      } else if (!((data?.categorias || []).length > 0 && montosValidation)) {
        noValidText =
          intl.locale === 'en' ? 'Incomplete offer amounts' : 'Montos de la oferta incompleta';
        return false;
      } else {
        return true;
      }
    }
  };

  const menuHandler = (menu) => {
    setOption(menu);
    logAnalytics({
      user: user,
      path: `users/${user.userId}/module_analytics`,
      type: 'menu',
      otherProps: {
        action: 'onClick',
        actionType: 'view',
        idLicitacion,
        result: menu,
      },
    });
  };

  return (
    <PageContent
      backHandler={() => navigate('/')}
      title={intl.formatMessage({ id: 'app.licitaciones.detailA' })}
      buttonHandler={
        loadingLicitacion || loadingData
          ? false
          : {
              name: intl.formatMessage({
                id: 'app.send',
                defaultMessage: 'Send',
              }),
              onClick: isValid() ? () => setModal({ open: true }) : null,
              isValid: isValid(),
              notValidText: noValidText,
            }
      }
      stage={stage}
      setStage={(x) => setStage(x)}
      stages={licitacion?.stages}
      isStageActive={isStageActive}
      showSelectButton={allowFetch}
    >
      <ContentContainer>
        <OptionsContainer>
          <div>
            <OptionButton selected={option === 'menuA'} onClick={() => menuHandler('menuA')}>
              <span>
                <FormattedMessage id="app.licitaciones.menuA" defaultMessage="" />
              </span>
            </OptionButton>
            <OptionButton selected={option === 'menuB'} onClick={() => menuHandler('menuB')}>
              <span>
                <FormattedMessage id="app.licitaciones.menuB" defaultMessage="" />
              </span>
            </OptionButton>
            <OptionButton selected={option === 'menuC'} onClick={() => menuHandler('menuC')}>
              <span>
                <FormattedMessage id="app.licitaciones.menuC" defaultMessage="" />
              </span>
            </OptionButton>
            <OptionButton selected={option === 'menuD'} onClick={() => menuHandler('menuD')}>
              <span>
                <FormattedMessage id="app.licitaciones.menuD" defaultMessage="" />
              </span>
            </OptionButton>
            <OptionButton selected={option === 'menuE'} onClick={() => menuHandler('menuE')}>
              <span>
                <FormattedMessage id="app.licitaciones.menuE" defaultMessage="" />
              </span>
            </OptionButton>
            {(licitacion?.contours || []).length > 0 && (
              <OptionButton selected={option === 'menuF'} onClick={() => menuHandler('menuF')}>
                <span>
                  <FormattedMessage
                    id={width > 500 ? 'app.licitaciones.menuF' : 'app.licitaciones.menuF2'}
                    defaultMessage=""
                  />
                </span>
              </OptionButton>
            )}
            {/* {(licitacion?.ortho || []).length > 0 && ( */}
            {/* <OptionButton selected={option === 'menuI'} onClick={() => menuHandler('menuI')}>
              <span>
                <FormattedMessage id={'app.licitaciones.menuI'} defaultMessage="" />
              </span>
            </OptionButton> */}
            {/* )} */}
          </div>
        </OptionsContainer>
        <DataContainer>
          {option === 'menuA' && allowFetch && (
            <LicitacionesDetailMenuA
              licitacion={licitacion}
              loading={loadingLicitacion || !licitacion}
              error={errorLicitacion}
              stage={stage}
              stageDates={stageDates || {}}
            />
          )}
          {option === 'menuB' && allowFetch && (
            <LicitacionesDetailMenuB
              folders={(data?.folders || []).filter((x) =>
                stage ? x.stage === stage.id : !x.stage
              )}
              files={(data?.files || []).filter((x) => (stage ? x.stage === stage.id : !x.stage))}
              loading={loadingData}
              error={errorData}
            />
          )}
          {/* {console.log({ stage })} */}
          {option === 'menuC' && allowFetch && (
            <LicitacionesDetailMenuC
              licitacion={licitacion}
              loading={loadingData}
              qa={(data?.qa || []).filter((x) =>
                stage ? x.stage === stage.id : !x.stage || x.stage === 'stage1'
              )}
              qaFiles={data?.qaFiles || []}
              error={errorData}
              forceReload={() => setForceReload((prevStatus) => !prevStatus)}
              isStageActive={isStageActive}
              stageDates={stageDates || {}}
              stage={stage}
            />
          )}
          {option === 'menuD' && allowFetch && (
            <LicitacionesDetailMenuD
              licitacion={licitacion}
              offerDocsSections={data?.offerDocsSections || []}
              offerDocs={data?.offerDocs || []}
              offerDocsFiles={data?.offerDocsFiles || []}
              loading={loadingData}
              error={errorData}
              files={files}
              comments={filesComments}
              stage={stage}
              isStageActive={isStageActive}
              periodoEntregaOfertas={periodoEntregaOfertas}
            />
          )}
          {option === 'menuE' && allowFetch && (
            <LicitacionesDetailMenuE
              licitacion={licitacion}
              secciones={data?.secciones || []}
              categorias={data?.categorias || []}
              valores={valores}
              loading={loadingData}
              error={errorData}
              stage={stage}
              isStageActive={isStageActive}
              periodoEntregaOfertas={periodoEntregaOfertas}
            />
          )}
          {option === 'menuF' && (licitacion?.contours || []).length > 0 && allowFetch && (
            <LicitacionesDetailMenuF
              licitacion={licitacion}
              loading={loadingLicitacion || !licitacion}
              error={errorLicitacion}
            />
          )}
          {option === 'menuI' && allowFetch && (
            <LicitacionesDetailMenuI
              licitacion={licitacion}
              loading={loadingLicitacion || !licitacion}
              error={errorLicitacion}
            />
          )}
          {!allowFetch && <LicitacionesDetailError />}
        </DataContainer>
      </ContentContainer>
      {modal.open && (
        <LicitacionesDetailSendModal
          open={modal.open}
          onClose={() => setModal(false)}
          licitacion={licitacion}
          forceReload={() => setForceReload((prevState) => !prevState)}
        />
      )}
    </PageContent>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
  height: 100%;
  position: relative;
  /* background-color: red; */
  margin-top: 50px;
`;

const DataContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  position: relative;
  background-color: ${(props) => props.theme.bgGray100};
  border-top: 1px solid lightgray;
`;

const OptionsContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 25px;

  > div {
    display: flex;
    width: 100%;
    /* border-bottom: 1px solid rgb(229, 231, 235); */
    padding: 0 15px;
  }
`;

const OptionButton = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 0px;
  padding: 4px 10px 2px 10px;
  border-bottom: ${(props) =>
    props.selected ? `2px solid ${props.theme.primaryColor}` : `2px solid whitesmoke`};
  background-color: ${(props) => (props.selected ? props.theme.primaryColor : 'whitesmoke')};
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-left: ${(props) =>
    props.selected ? `1px solid ${props.theme.primaryColor}` : '1px solid #dadbdd'};
  border-right: ${(props) =>
    props.selected ? `1px solid ${props.theme.primaryColor}` : '1px solid #dadbdd'};
  border-top: ${(props) =>
    props.selected ? `1px solid ${props.theme.primaryColor}` : '1px solid #dadbdd'};
  white-space: nowrap;
  overflow: hidden;
  transition: 0.1s all ease-in-out;
  cursor: pointer;

  > span {
    font-size: 13px;
    font-weight: 300;
    color: ${(props) => (props.selected ? 'white' : 'black')};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    transition: 0.2s all ease-in-out;
    background-color: ${(props) => (props.selected ? props.theme.primaryColor : '#E8E8E8')};
    border-bottom: ${(props) =>
      props.selected ? `2px solid ${props.theme.primaryColor}` : '2px solid darkgrey'};
  }
`;
