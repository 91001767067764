import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function NoTableRecordToDisplay({ padding }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', padding: padding || '60px 0' }}>
      <span
        style={{
          textAlign: 'center',
          fontWeight: '300',
          fontSize: '14px',
        }}
      >
        <FormattedMessage id="app.norecords" />
      </span>
    </div>
  );
}
