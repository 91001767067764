import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  selectLicitaciones,
  selectLicitacionesError,
  selectLicitacionesLoading,
} from '../../features/licitaciones/licitacionesSlice';
import PageContent from '../generales/PageContent';
import PageLoadingError from '../generales/PageLoadingError';
import { FormattedMessage } from 'react-intl';
import { selectUser } from '../../features/user/userSlice';
import LicitacionesGrid from './Licitaciones_grid';

export default function Licitaciones() {
  const user = useSelector(selectUser);
  const licitaciones = useSelector(selectLicitaciones);
  const loading = useSelector(selectLicitacionesLoading);
  const error = useSelector(selectLicitacionesError);

  return (
    <PageContent>
      <ContentContainer>
        <Title>
          <span>
            <FormattedMessage id="app.welcome" /> {user.name.toUpperCase()}!,
          </span>
        </Title>
        <SubTitle>
          <span>
            <FormattedMessage id="app.licitaciones.pageDescription" />,
          </span>
        </SubTitle>
        {error ? (
          <PageLoadingError />
        ) : (
          <GridContainer>
            <LicitacionesGrid loading={loading} licitaciones={licitaciones} />
          </GridContainer>
        )}
      </ContentContainer>
    </PageContent>
  );
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 25px 0 0 0;
`;

const Title = styled.div`
  display: flex;
  font-size: 18px;
  font-weight: 500;
  padding: 0 25px;
`;

const SubTitle = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 300;
  padding: 0 25px 25px 25px;
  border-bottom: 1px solid lightgrey;
`;

const GridContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.bgGray100};
`;
